import shirt from "./assets/images/shirt.png";
import jacket from "./assets/images/jacket.png";
import hoodie from "./assets/images/hoodie.png";
import suit from "./assets/images/suit.png";
import pants from "./assets/images/pants.png";
import shorts from "./assets/images/shorts.png";
import dress from "./assets/images/dress.png";
import thawb from "./assets/images/thawb.png";
import sleepwear from "./assets/images/sleepwear.png";
import waistcoat from "./assets/images/waistcoat.png";
import coat from "./assets/images/coat.png";
import coverall from "./assets/images/coveralls.png";
import scissors from "./assets/images/scissors.png";
import special from "./assets/images/special.png";
import fashion from "./assets/images/fashion.png";
import sweater from "./assets/images/sweater.png";

// Reusable functions for the measurements page
export const clothingList = [
  { label: "Dishdasha", imageUrl: thawb },
  { label: "Shirt", imageUrl: shirt },
  { label: "Classic Jacket", imageUrl: jacket },
  { label: "Hoodie", imageUrl: hoodie },
  { label: "Sweater", imageUrl: sweater },
  { label: "Suit", imageUrl: suit },
  { label: "Pants", imageUrl: pants },
  { label: "Shorts", imageUrl: shorts },
  { label: "Dress", imageUrl: dress },
  { label: "Sleepwear", imageUrl: sleepwear },
  { label: "Coat", imageUrl: coat },
  { label: "Vest", imageUrl: waistcoat },
  { label: "Coveralls", imageUrl: coverall },
  { label: "Other", imageUrl: fashion },
  { label: "Alterations", imageUrl: scissors },
  { label: "Special Order", imageUrl: special },
];

export const hasShirt = (list) => {
  return list.some((item) => item.clothing_item === "Shirt");
};

export const dishdashaLength = (list) => {
  return (
    list.some((item) => item.clothing_item === "Dishdasha") ||
    list.some((item) => item.clothing_item === "Sleepwear") ||
    list.some((item) => item.clothing_item === "Coveralls")
  );
};

export const garmentLength = (list) => {
  return (
    list.some((item) => item.clothing_item === "Shirt") ||
    list.some((item) => item.clothing_item === "Sweater") ||
    list.some((item) => item.clothing_item === "Hoodie") ||
    list.some((item) => item.clothing_item === "Suit") ||
    list.some((item) => item.clothing_item === "Coat") ||
    list.some((item) => item.clothing_item === "Vest") ||
    list.some((item) => item.clothing_item === "Classic Jacket")
  );
};

export const shortsLength = (list) => {
  return list.some((item) => item.clothing_item === "Shorts");
};

export const dressLength = (list) => {
  return list.some((item) => item.clothing_item === "Dress");
};

export const hasOther = (list) => {
  return list.some((item) => item.clothing_item === "Other");
};

export const hasSpecialOrder = (list) => {
  return list.some((item) => item.clothing_item === "Special Order");
};

export const upperMeasurements = (list) => {
  return (
    list.some((item) => item.clothing_item === "Dishdasha") ||
    list.some((item) => item.clothing_item === "Sweater") ||
    list.some((item) => item.clothing_item === "Classic Jacket") ||
    list.some((item) => item.clothing_item === "Hoodie") ||
    list.some((item) => item.clothing_item === "Suit") ||
    list.some((item) => item.clothing_item === "Dress") ||
    list.some((item) => item.clothing_item === "Sleepwear") ||
    list.some((item) => item.clothing_item === "Coat") ||
    list.some((item) => item.clothing_item === "Vest") ||
    list.some((item) => item.clothing_item === "Coveralls") ||
    list.some((item) => item.clothing_item === "Other") ||
    list.some((item) => item.clothing_item === "Shirt")
  );
};

export const lowerMeasurements = (list) => {
  return (
    list.some((item) => item.clothing_item === "Suit") ||
    list.some((item) => item.clothing_item === "Pants") ||
    list.some((item) => item.clothing_item === "Shorts") ||
    list.some((item) => item.clothing_item === "Dress") ||
    list.some((item) => item.clothing_item === "Sleepwear") ||
    list.some((item) => item.clothing_item === "Coat") ||
    list.some((item) => item.clothing_item === "Vest") ||
    list.some((item) => item.clothing_item === "Coveralls") ||
    list.some((item) => item.clothing_item === "Other")
  );
};

export const isBottom = (list) => {
  return (
    list.some((item) => item.clothing_item === "Pants") ||
    list.some((item) => item.clothing_item === "Shorts")
  );
};

export const needsSleeve = (list) => {
  return (
    list.some((item) => item.clothing_item === "Dishdasha") ||
    list.some((item) => item.clothing_item === "Classic Jacket") ||
    list.some((item) => item.clothing_item === "Hoodie") ||
    list.some((item) => item.clothing_item === "Suit") ||
    list.some((item) => item.clothing_item === "Coat") ||
    list.some((item) => item.clothing_item === "Coveralls") ||
    list.some((item) => item.clothing_item === "Sweater")
  );
};

export const hasShirtSleeve = (list) => {
  return list.some((item) => item.clothing_item === "Shirt");
};

export const hasDressSleeve = (list) => {
  return list.some((item) => item.clothing_item === "Dress");
};

export const hasSleepwearSleeve = (list) => {
  return list.some((item) => item.clothing_item === "Sleepwear");
};

export const hasBackWidth = (list) => {
  return (
    list.some((item) => item.clothing_item === "Suit") ||
    list.some((item) => item.clothing_item === "Coat") ||
    list.some((item) => item.clothing_item === "Vest")
  );
};

export const hasAlterations = (list) => {
  return list.some((item) => item.clothing_item === "Alterations");
};

// Helper function to dynamically display area names
export function selectArea(mainElement, setGovernorate, setAreas) {
  setGovernorate(mainElement.value);
  switch (mainElement.value) {
    case "Al Aasima":
      setAreas([
        "Abdulla Al-Salem",
        "Adailiya",
        "Bnaid Al-Qar",
        "Daʿiya",
        "Dasma",
        "Doha",
        "Doha Port",
        "Faiha'",
        "Failaka",
        "Granada",
        "Jaber Al-Ahmad City",
        "Jibla",
        "Kaifan",
        "Khaldiya",
        "Mansūriya",
        "Mirgab",
        "Nahdha",
        "North West Sulaibikhat",
        "Nuzha",
        "Qadsiya",
        "Qurtuba",
        "Rawda",
        "Shamiya",
        "Sharq",
        "Shuwaikh",
        "Shuwaikh Industrial Area",
        "Shuwaikh Port",
        "Sulaibikhat",
        "Surra",
        "Umm an Namil Island",
        "Yarmouk",
      ]);
      break;
    case "Hawalli":
      setAreas([
        "Anjafa",
        "Bayān",
        "Bi'da",
        "Hawally",
        "Hittin",
        "Jabriya",
        "Maidan Hawalli",
        "Mishrif",
        "Mubarak Al-Jabir",
        "Nigra",
        "Rumaithiya",
        "Salam",
        "Salmiya",
        "Salwa",
        "Sha'ab",
        "Shuhada",
        "Siddiq",
        "South Surra",
        "Zahra",
      ]);
      break;
    case "Mubarak Al-Kabeer":
      setAreas([
        "Abu Al Hasaniya",
        "Abu Futaira",
        "Adān",
        "Al Qurain",
        "Al-Qusour",
        "Fintās",
        "Funaitīs",
        "Misīla",
        "Mubarak Al-Kabeer",
        "Sabah Al-Salem",
        "Sabhān",
        "South Wista",
        "Wista",
      ]);
      break;
    case "Ahmadi":
      setAreas([
        "Abu Halifa",
        "Abdullah Port",
        "Ahmadi",
        "Ali As-Salim",
        "Aqila",
        "Bar Al Ahmadi",
        "Bneidar",
        "Dhaher",
        "Fahaheel",
        "Fahad Al-Ahmad",
        "Hadiya",
        "Jaber Al-Ali",
        "Jawaher Al Wafra",
        "Jilei'a",
        "Khairan",
        "Mahbula",
        "Mangaf",
        "Miqwa'",
        "New Khairan City",
        "New Wafra",
        "Nuwaiseeb",
        "Riqqa",
        "Sabah Al-Ahmad City",
        "Sabah Al Ahmad Sea City",
        "Sabahiya",
        "Shu'aiba (North & South)",
        "South Sabahiya",
        "Wafra",
        "Zoor",
        "Zuhar",
      ]);
      break;
    case "Farwaniya":
      setAreas([
        "Abdullah Al-Mubarak",
        "Airport District",
        "Andalous",
        "Ardiya",
        "Ardiya Herafiya",
        "Ardiya Industrial Area",
        "Ashbelya",
        "Dhajeej",
        "Farwaniya",
        "Fordous",
        "Jleeb Al-Shuyoukh",
        "Khaitan",
        "Omariya",
        "Rabiya",
        "Rai",
        "Al-Riggae",
        "Rihab",
        "Sabah Al-Nasser",
        "Sabaq Al Hajan",
      ]);
      break;
    case "Jahra":
      setAreas([
        "Abdali",
        "Al Nahda / East Sulaibikhat",
        "Amghara",
        "Bar Jahra",
        "Jahra",
        "Jahra Industrial Area",
        "Kabad",
        "Naeem",
        "Nasseem",
        "Oyoun",
        "Qasr",
        "Saad Al Abdullah City",
        "Salmi",
        "Sikrab",
        "South Doha / Qairawān",
        "Subiya",
        "Sulaibiya",
        "Sulaibiya Agricultural Area",
        "Taima",
        "Waha",
      ]);
      break;
    default:
      setAreas([]);
  }
}

// Helper function to apply different animations
export function applyAnimation(orderStatus) {
  switch (orderStatus) {
    case "Not Started":
      return "bg-[black] text-[white] animate-pulse";
    case "Measuring In Progress":
      return "bg-[yellow] animate-pulse";
    case "Cutting In Progress":
      return "bg-[yellow] animate-pulse";
    case "Tailoring In Progress":
      return "bg-[yellow] animate-pulse";
    case "Driving In Progress":
      return "bg-[yellow] animate-pulse";
    case "QA/QC In Progress":
      return "bg-[yellow] animate-pulse";
    case "Measuring Complete":
      return "bg-[lightgreen] animate-pulse";
    case "Cutting Complete":
      return "bg-[lightgreen] animate-pulse";
    case "Tailoring Complete":
      return "bg-[lightgreen] animate-pulse";
    case "QA/QC Complete":
      return "bg-[lightgreen] animate-pulse";
    case "Driving Complete":
      return "bg-[lightgreen] animate-pulse";
    case "Measurer Assigned":
      return "bg-[orange] animate-pulse";
    case "Cutting Assigned":
      return "bg-[orange] animate-pulse";
    case "Measurer Accepted":
      return "bg-[orange] animate-pulse";
    case "Cutter Accepted":
      return "bg-[orange] animate-pulse";
    case "Tailor Accepted":
      return "bg-[orange] animate-pulse";
    case "Driver Accepted":
      return "bg-[orange] animate-pulse";
    case "Tailoring Assigned":
      return "bg-[orange] animate-pulse";
    case "QA/QC Assigned":
      return "bg-[orange] animate-pulse";
    case "Ready for Pick Up":
      return "bg-[orange] animate-pulse";
    case "Awaiting Delivery":
      return "bg-[orange] animate-pulse";
    case "Measurer Rejected":
      return "bg-[red] text-[white] animate-pulse";
    case "Cutter Rejected":
      return "bg-[red] text-[white] animate-pulse";
    case "Tailor Rejected":
      return "bg-[red] text-[white] animate-pulse";
    case "Driver Rejected":
      return "bg-[red] text-[white] animate-pulse";
    case "Requires Alteration":
      return "bg-[red] text-[white] animate-pulse";
    case "Order Delivered":
      return "bg-[green] text-[white] animate-pulse";
    default:
      return "";
  }
}

// Array containing are names
export const areaNames = [
  "Abdulla Al-Salem",
  "Adailiya",
  "Bnaid Al-Qar",
  "Daʿiya",
  "Dasma",
  "Doha",
  "Doha Port",
  "Faiha'",
  "Failaka",
  "Granada",
  "Jaber Al-Ahmad City",
  "Jibla",
  "Kaifan",
  "Khaldiya",
  "Mansūriya",
  "Mirgab",
  "Nahdha",
  "North West Sulaibikhat",
  "Nuzha",
  "Qadsiya",
  "Qurtuba",
  "Rawda",
  "Shamiya",
  "Sharq",
  "Shuwaikh",
  "Shuwaikh Industrial Area",
  "Shuwaikh Port",
  "Sulaibikhat",
  "Surra",
  "Umm an Namil Island",
  "Yarmouk",
];

// Helper function to determine if the user lacks specific roles
export const lacksRoles = (roles, user) => {
  return !user?.role?.some((r) => roles.includes(r.type));
};

// Helper function to determine if the user has specific roles
export const hasRoles = (roles, user) => {
  return user?.role?.some((r) => roles.includes(r.type));
};
