import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Login from "./pages/Login";
import NotFound from "./pages/404";
import { Suspense, useCallback, useEffect } from "react";
import { Slide, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useStoreActions } from "easy-peasy";
import { jwtDecode } from "jwt-decode";
import { appRoutes } from "./routes";
import ScrollToTop from "./components/ScrollToTop";
import ForgotPassword from "./pages/users/ForgotPassword";
import ResetPassword from "./pages/users/ResetPassword";
import axios from "axios";
import { useTranslation } from "react-i18next";

function App() {
  const authKey = localStorage.getItem("jwt");
  const setUser = useStoreActions((state) => state.setUser);
  const navigate = useNavigate();
  const {i18n} = useTranslation();

  document.body.dir = i18n.dir();

  const isTokenExpired = useCallback((token) => {
    try {
      const payloadBase64 = token.split(".")[1];
      const decodedJson = atob(payloadBase64);
      const decoded = JSON.parse(decodedJson);
      const exp = decoded.exp;
      const now = Date.now().valueOf() / 1000;
      return now > exp;
    } catch (error) {
      console.error("Token parsing error:", error);
      return true; // Treat any error as an expired token
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("jwt");

    if (!token || isTokenExpired(token)) {
      localStorage.clear();
      navigate("/users/login");
      toast.warn("Session expired. Please log in again.");
    } else {
      // Token is valid, proceed with user setup
      const decoded = jwtDecode(token);
      setUser({
        id: decoded.id,
        email: decoded.email,
        role: Array.isArray(decoded.role) ? decoded.role : [decoded.role], // Ensure roles are stored as an array
        language: decoded.language,
      });
    }
  }, [isTokenExpired, navigate, setUser]);

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          navigate("/users/login");
          toast.warn("Session expired. Please log in again.");
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [navigate]);

  useEffect(() => {
    const checkToken = setInterval(() => {
      const token = localStorage.getItem("jwt");
      if (!token || isTokenExpired(token)) {
        localStorage.clear();
        navigate("/users/login");
        toast.warn("Session expired. Please log in again.");
      }
    }, 5 * 60 * 1000); // Check every 5 minutes

    return () => clearInterval(checkToken);
  }, [isTokenExpired, navigate]);

  return (
    <Suspense
      fallback={
        <div className="w-screen h-screen flex items-center justify-center">
          <i className="text-[5rem] text-khamco-ribbon fas fa-spinner animate-spin"></i>
        </div>
      }
    >
      <ScrollToTop />
      <Routes>
        <Route exact path="/users/login" element={<Login />} />
        {appRoutes.map((route) => (
          <Route
            key={route.path}
            exact
            path={route.path}
            element={
              authKey ? <route.component /> : <Navigate to="/users/login" />
            }
          />
        ))}
        {/* Misc Routes */}
        <Route
          exact
          path="/users/forgot-password"
          element={<ForgotPassword />}
        />
        <Route
          exact
          path="/users/reset-password/:token"
          element={<ResetPassword />}
        />
        <Route exact path="/unauthorized" element={<NotFound />} />
        <Route exact path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Slide}
        autoClose={5000}
      />
    </Suspense>
  );
}

export default App;
